<template>
    <div>
        <el-dialog :title="title" :visible.sync="isShow" width="800px" :before-close="handleClose" :close-on-click-modal="false">

            <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" class="dialogForm">

                <el-row :gutter="30">
                    <el-col :span="12">
                        <el-form-item prop="userName">
                            <selects @handleChange="handleSelect($event,0)" ref="userName" :options="proManager" :placeholder="'负责人'">
                            </selects>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="planBegin">
                            <el-date-picker class="logInput" v-model="dialogForm.planBegin" type="date" placeholder="开始时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="30">
                    <el-col :span="12">
                        <el-form-item prop="title">
                            <el-input v-model="dialogForm.title" placeholder="计划名称" ref="title" class="logInput">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="type">
                            <selects @handleChange="handleSelect($event,1)" ref="type" :options="type" :placeholder="'计划类型'">
                            </selects>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="30">
                    <el-col :span="24">
                        <el-form-item prop="content">
                            <div ref="editor" style="text-align:left"></div>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="createWorkPlan" :loading="isloading">
                    {{!isloading ? '确定': '创建中...'}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { PLAN_FINDBYID, POST_QINIUTOKEN } from "../../../request/api";
import { selects } from "../tools";
import E from "wangeditor";
var editor;
// import editor from "./editor.vue";
export default {
    data() {
        return {
            isShow: false,
            isloading: false,
            title: "新增工作计划",

            dialogForm: {
                projectId: null, //项目id
                workPlanId: null, //工作计划id
                userName: "", //用户名  （负责人）
                title: "", //计划名称
                type: 1, //计划类型
                content: "", //计划内容
                planBegin: "", //计划开始时间
            },

            refList: ["userName", "type"],
            inputList: ["title", "planBegin"],

            dialogRules: {
                userName: [
                    {
                        required: true,
                        message: "请输入缺陷修复人",
                        trigger: "change",
                    },
                ],
                title: [
                    {
                        required: true,
                        message: "请输入分类名称",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "请选择计划类型",
                        trigger: "change",
                    },
                ],
                content: [
                    {
                        required: true,
                        message: "请输入工作内容",
                        trigger: "blur",
                    },
                ],
                planBegin: [
                    {
                        required: true,
                        message: "请选择开始时间",
                        trigger: "change",
                    },
                ],
            },

            //   负责人
            proManager: [],
            //计划类型
            type: [
                { value: 0, label: "日" },
                { value: 1, label: "周" },
                { value: 2, label: "月度" },
                { value: 3, label: "年度" },
            ],
            qiniuData: {
                token: "",
            },
        };
    },
    mounted() {
        this.proManager = this.$store.state.Member;
    },
    methods: {
        getText() {
            var That = this;
            editor = new E(this.$refs.editor);
            editor.config.onchange = function (html) {
                That.dialogForm.content = html;
            };
            editor.config.uploadImgMaxLength = 1;
            editor.config.uploadImgServer = "https://upload.qiniup.com";
            editor.config.uploadFileName = "file";
            editor.config.uploadImgParams = {
                token: this.qiniuData.token,
            };
            editor.config.uploadImgHooks = {
                customInsert: function (insertImgFn, result) {
                    let url = "http://qiniu.hearming.com/" + result.key;
                    insertImgFn(url);
                },
            };
            editor.create();
        },
        handleSelect(val, type) {
            if (type == 0) {
                this.dialogForm.userName = val;
            } else if (type == 1) {
                this.dialogForm.type = val;
            }
        },
        openNewDefect(id, copy) {
            this.isShow = true;
            if (!this.qiniuData.token) {
                POST_QINIUTOKEN().then(({ data: res }) => {
                    this.qiniuData.token = res.data;
                    this.getText();
                });
            } else {
                editor.txt.html("");
            }

            this.dialogForm.projectId =
                window.sessionStorage.getItem("currentId");
            if (!this.dialogForm.projectId)
                return this.$notify.error({
                    title: "错误",
                    message: "请先选择一个项目",
                });
            if (id != -1) {
                this.title = "复制工作计划";
                PLAN_FINDBYID({ id })
                    .then(({ data: res }) => {
                        editor.txt.html(res.data.content);
                        if (!copy) {
                            //如果不是复制，则携带ID
                            this.title = "编辑工作计划";
                            this.dialogForm.workPlanId = res.data.workPlanId;
                        }
                        this.inputList.forEach((element) => {
                            //给input输入框赋值
                            this.dialogForm[element] = res.data[element]; //给表单赋值
                        });
                        this.refList.forEach((element) => {
                            //给下拉选择框赋值
                            this.$refs[element].setValue(res.data[element]);
                            this.dialogForm[element] = res.data[element]; //给表单赋值
                        });
                        this.$refs["type"].setValue(parseInt(res.data.type));
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.title = "新增工作计划";
                // 默认负责人选择当前用户
                const realName = this.$store.state.userName;
                this.$nextTick(() => {
                    this.$refs["userName"].setValue(realName);
                    this.dialogForm["userName"] = realName; //给表单赋值
                });
            }
        },
        //获取富文本编辑器信息
        getEditor(val) {
            this.dialogForm.content = val;
        },
        // 关闭确认
        handleClose() {
            this.resetForm();
            this.isShow = false;
        },
        //重置表单
        resetForm() {
            this.dialogForm.workPlanId = "";
            this.$refs.dialogForm.resetFields();
            this.refList.forEach((element) => {
                this.$refs[element].resetValue();
            });
        },
        // 创建新缺陷
        createWorkPlan() {
            this.$refs.dialogForm.validate((valid) => {
                if (valid) {
                    this.isloading = true;
                    this.$emit("createWorkPlan", this.dialogForm);
                    setTimeout(() => {
                        this.isloading = false;
                    }, 500);
                }
            });
        },
    },
    components: {
        selects,
    },
};
</script>

<style lang="less" scoped>
.logInput {
    width: 200px;
    height: 36px;

    /deep/ .el-input__inner {
        height: 36px;
        border-radius: 0;
        line-height: 36px;
    }
}
</style>