<template>
    <div>
        <el-dialog :title="title" :visible.sync="isShow" width="800px" :before-close="handleClose" :close-on-click-modal="false">

            <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" class="dialogForm">

                <el-row :gutter="30">
                    <el-col :span="12">
                        <el-form-item prop="proName">
                            <el-input v-model="dialogForm.proName" placeholder="项目名称" ref="proName" class="logInput">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="userName">
                            <selects @handleChange="handleSelect($event,0)" ref="userName" :options="proManager" :placeholder="'报告人'">
                            </selects>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="30">
                    <el-col :span="12">
                        <el-form-item prop="type">
                            <selects @handleChange="handleSelect($event,1)" ref="type" :options="type" :placeholder="'报告类型'">
                            </selects>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="reportTime">
                            <el-date-picker class="logInput" v-model="dialogForm.reportTime" type="date" placeholder="报告日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="30">
                    <el-col :span="24">
                        <el-form-item prop="content">
                            <div ref="editor" style="text-align:left"></div>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="createReport" :loading="isloading">
                    {{!isloading ? '确定': '创建中...'}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { REPORT_FINDBYID, POST_QINIUTOKEN } from "../../../request/api";
import { selects } from "../tools";
import E from "wangeditor";
var editor;
export default {
    data() {
        return {
            isShow: false,
            isloading: false,
            title: "新增工作报告",

            dialogForm: {
                proId: null, //项目id
                workReportId: null, //工作报告id
                proName: "", //项目名称
                userName: "", //用户名  （负责人）
                type: 1, //报告类型
                content: "", //工作内容
                reportTime: "", //报告时间
            },

            refList: ["userName", "type"],
            inputList: ["proName", "reportTime"],

            dialogRules: {
                proName: [
                    {
                        required: true,
                        message: "请输入项目名称",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        max: 20,
                        message: "长度在 3 到 20 个字符",
                        trigger: "blur",
                    },
                ],
                userName: [
                    {
                        required: true,
                        message: "请输入缺陷修复人",
                        trigger: "change",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "请选择报告类型",
                        trigger: "change",
                    },
                ],
                content: [
                    {
                        required: true,
                        message: "请输入报告内容",
                        trigger: "blur",
                    },
                ],
                reportTime: [
                    {
                        required: true,
                        message: "请选择报告时间",
                        trigger: "change",
                    },
                ],
            },

            //   负责人
            proManager: [],
            //报告类型
            type: [
                { value: 0, label: "日报" },
                { value: 1, label: "周报" },
                { value: 2, label: "月报" },
            ],
            qiniuData: {
                token: "",
            },
        };
    },
    mounted() {
        this.proManager = this.$store.state.Member;

        this.dialogForm.reportTime = this.getTime();
    },
    methods: {
        getTime() {
            let myDate = new Date();
            let year = myDate.getFullYear();
            let moth = myDate.getMonth()+1;
            if(moth<10){
                moth='0'+moth
            }
            let day=myDate.getDate();
            if(day<10){
                day='0'+day
            }
            return year+'-'+moth+'-'+day
        },
        getText() {
            var That = this;
            editor = new E(this.$refs.editor);
            editor.config.onchange = function (html) {
                That.dialogForm.content = html;
            };
            editor.config.uploadImgMaxLength = 1;
            editor.config.uploadImgServer = "https://upload.qiniup.com";
            editor.config.uploadFileName = "file";
            editor.config.uploadImgParams = {
                token: this.qiniuData.token,
            };
            editor.config.uploadImgHooks = {
                customInsert: function (insertImgFn, result) {
                    let url = "http://qiniu.hearming.com/" + result.key;
                    insertImgFn(url);
                },
            };
            editor.create();
        },
        handleSelect(val, type) {
            if (type == 0) {
                this.dialogForm.userName = val;
            } else if (type == 1) {
                this.dialogForm.type = val;
            }
        },
        openNewDefect(id, copy) {
            this.isShow = true;
            if (!this.qiniuData.token) {
                POST_QINIUTOKEN().then(({ data: res }) => {
                    this.qiniuData.token = res.data;
                    this.getText();
                });
            } else {
                editor.txt.html("");
            }
            this.dialogForm.proId = window.sessionStorage.getItem("currentId");
            if (!this.dialogForm.proId) {
                this.$notify.error({
                    title: "错误",
                    message: "请先选择一个项目",
                });
            }
            if (id != -1) {
                this.title = "复制工作报告";
                REPORT_FINDBYID({ id })
                    .then(({ data: res }) => {
                        editor.txt.html(res.data.content);
                        if (!copy) {
                            //如果不是复制，则携带ID
                            this.title = "编辑工作报告";
                            this.dialogForm.workReportId =
                                res.data.workReportId;
                        }
                        this.inputList.forEach((element) => {
                            //给input输入框赋值
                            this.dialogForm[element] = res.data[element]; //给表单赋值
                        });
                        this.refList.forEach((element) => {
                            //给下拉选择框赋值
                            this.$refs[element].setValue(res.data[element]);
                            this.dialogForm[element] = res.data[element]; //给表单赋值
                        });
                        this.$refs["type"].setValue(parseInt(res.data.type));
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.title = "新增工作报告";
                const realName = this.$store.state.userName;
                this.$nextTick(() => {
                    this.dialogForm.proName =
                        window.sessionStorage.getItem("currentlabel");
                    this.$refs["userName"].setValue(realName);
                    this.dialogForm["userName"] = realName; //给表单赋值
                    this.$refs.type.setValue(0);
                });
            }
        },
        //获取富文本编辑器信息
        getEditor(val) {
            this.dialogForm.content = val;
        },
        // 关闭确认
        handleClose() {
            this.resetForm();
            this.isShow = false;
        },
        //重置表单
        resetForm() {
            this.dialogForm.workReportId = "";
            this.$refs.dialogForm.resetFields();
            this.refList.forEach((element) => {
                this.$refs[element].resetValue();
            });
        },
        // 创建日报
        createReport() {
            this.$refs.dialogForm.validate((valid) => {
                if (valid) {
                    this.isloading = true;
                    // console.log(this.dialogForm);
                    this.$emit("createReport", this.dialogForm);
                    setTimeout(() => {
                        this.isloading = false;
                    }, 500);
                }
            });
        },
    },
    components: {
        selects,
    },
};
</script>

<style lang="less" scoped>
.logInput {
    width: 200px;
    height: 36px;

    /deep/ .el-input__inner {
        height: 36px;
        border-radius: 0;
        line-height: 36px;
    }
}
</style>