<template>
  <div>
    <el-dialog :title="title" :visible.sync="isShow" width="900px" :before-close="handleClose"
      :close-on-click-modal="false">

      <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" class="dialogForm">

        <el-row :gutter="30">
          <el-col :span="6">
            <el-form-item prop="title">
              <el-input v-model="dialogForm.title" placeholder="绩效名称" ref="title" class="logInput">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="type">
              <selects @handleChange="handleSelect($event,1)" ref="type" :options="type"
                :placeholder="'计划类型'">
              </selects>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="periodBegin">
              <el-date-picker class="logInput" v-model="dialogForm.periodBegin" type="date"
                placeholder="开始时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="periodEnd">
              <el-date-picker class="logInput" v-model="dialogForm.periodEnd" type="date"
                placeholder="结束时间" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="6">
            <el-form-item prop="year">
              <el-date-picker class="logInput" v-model="dialogForm.year" type="year"
                placeholder="对应年度" format="yyyy 年" value-format="yyyy">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="month">
              <el-date-picker class="logInput" v-model="dialogForm.month" type="month"
                placeholder="对应月份" format=" MM 月" value-format="MM">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="content">
              <el-input type="textarea" placeholder="工作绩效" rows="4" v-model="dialogForm.content"
                maxlength="1024" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="study">
              <el-input type="textarea" placeholder="学习培训" rows="4" v-model="dialogForm.study"
                maxlength="1024" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="lacking">
              <el-input type="textarea" placeholder="工作内不足处" rows="4" v-model="dialogForm.lacking"
                maxlength="1024" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="mem">
              <el-input type="textarea" placeholder="绩效备注,述求 （非必填）" rows="4"
                v-model="dialogForm.mem" maxlength="1024" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="createWorkScore" :loading="isloading">
          {{!isloading ? '确定': '创建中...'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { SCORE_FINDBYID } from "../../../request/api";
import { selects } from "../tools";

export default {
  data() {
    return {
      isShow: false,
      isloading: false,
      title: "新增工作绩效",

      dialogForm: {
        projectId: null, //项目id
        scoreId: null, //工作计划id
        title: "", //绩效名称
        type: 1, //绩效类型
        content: "", //绩效内容
        study: "", //绩效工作内容学习培训
        lacking: "", ///工作内不足处
        mem: "", ///绩效备注,述求
        periodBegin: "", //开始时间
        periodEnd: "", //结束时间
        year: "", //对应年度
        month: "", //对应月份
      },

      refList: ["type"],
      inputList: [
        "title",
        "content",
        "study",
        "lacking",
        "mem",
        "periodBegin",
        "periodEnd",
        "year",
        "month",
      ],

      dialogRules: {
        title: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        type: [
          { required: true, message: "请选择计划类型", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入工作内容", trigger: "blur" },
        ],
        study: [
          { required: true, message: "绩效工作内容学习培训", trigger: "blur" },
        ],
        lacking: [{ required: true, message: "工作内不足处", trigger: "blur" }],
        mem: [{ required: false, message: "绩效备注,述求 ", trigger: "blur" }],
        periodBegin: [
          { required: true, message: "计划开始时间", trigger: "change" },
        ],
        periodEnd: [
          { required: true, message: "计划结束时间", trigger: "change" },
        ],
        year: [{ required: true, message: "对应年度", trigger: "change" }],
        month: [{ required: true, message: "对应月份", trigger: "change" }],
      },

      //计划类型
      type: [
        { value: 0, label: "日" },
        { value: 1, label: "周" },
        { value: 2, label: "月度" },
        { value: 3, label: "年度" },
      ],
    };
  },
  methods: {
    handleSelect(val, type) {
      if (type == 1) {
        this.dialogForm.type = val;
      }
    },
    openNewDefect(id, copy) {
      this.dialogForm.projectId = window.sessionStorage.getItem("currentId");
      if (!this.dialogForm.projectId)
        return this.$notify.error({
          title: "错误",
          message: "请先选择一个项目",
        });
      if (id != -1) {
        this.title = "复制工作绩效";
        SCORE_FINDBYID({ id })
          .then(({ data: res }) => {
            if (!copy) {
              //如果不是复制，则携带ID
              this.title = "编辑工作绩效";
              this.dialogForm.scoreId = res.data.scoreId;
            }
            this.inputList.forEach((element) => {
              //给input输入框赋值
              if (element == "year" || element == "month") {
                this.dialogForm[element] = res.data[element].toString();
              } else {
                this.dialogForm[element] = res.data[element]; //给表单赋值
              }
            });
            this.refList.forEach((element) => {
              //给下拉选择框赋值
              this.$refs[element].setValue(res.data[element]);
              this.dialogForm[element] = res.data[element]; //给表单赋值
            });
            this.$refs["type"].setValue(parseInt(res.data.type));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // 间隔一点时间先让组件初始化
        this.title = "新增工作绩效";
      }
      this.isShow = true;
    },
    // 关闭确认
    handleClose() {
      this.resetForm();
      this.isShow = false;
    },
    //重置表单
    resetForm() {
      this.dialogForm.scoreId = "";
      this.$refs.dialogForm.resetFields();
      this.refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
    },
    // 创建新绩效
    createWorkScore() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.isloading = true;
          this.$emit("createWorkScore", this.dialogForm);
          setTimeout(() => {
            this.isloading = false;
          }, 500);
        }
      });
    },
  },
  components: {
    selects,
  },
};
</script>

<style lang="less" scoped>
.logInput {
  width: 200px;
  height: 36px;

  /deep/ .el-input__inner {
    height: 36px;
    border-radius: 0;
    line-height: 36px;
  }
}
</style>